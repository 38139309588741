/**
 * Application Variables
 * These variables are used to run the project locally and in the GitLab pipeline.
 */

const getEnvironment = (ENV) => {
  const environment = {
    development: 'development',
    staging: 'staging',
    onboarding: 'onboarding',
    production: 'production',
  };

  if (environment[ENV]) {
    return ENV;
  }

  return 'local';
};

// !!! Do not use process.env.ENV here
// process.env.REACT_APP_ENV is not defined on browser in pre-login
// process.env.GATSBY_ENV is not defined on browser in post-login
const ENVIRONMENT = getEnvironment(process.env.REACT_APP_ENV || process.env.GATSBY_ENV);

/**
 * Environments
 * These are the environments where the application runs:
 * For each environment there are different values, urls, endpoints and domains.
 * Staging, Onboarding and Production env configs are necessary to deploy the project with the GitLab pipeline.
 * Development env config is used only to run the project locally.
 */
const common = {
  // api

  LOCALE_PREFIX:'/en-us/',
  STORYBLOK_HOME_SLUG: 'home',
  STORYBLOK_VERSION: 'draft',
  STORYBLOK_SPACEID: 237732,
  BUNDLE_REPORT: false,
  REACT_APP_ENABLE_OKTA: true,
  GATSBY_GRAPHQL_SERVER_MOCKED: true,
  REACT_APP_YODLEE_FAST_LINK_ACCOUNT_ID: 'foo',
  REACT_APP_YODLEE_FAST_LINK_PROVIDER_ACCOUNT_ID: 'bar',
  REACT_APP_GTM_ID: 'GTM-NWZX78L',
  REACT_APP_DATADOG_APPLICATION_ID: 'ecd70824-3e9a-49a2-bd0d-b7f4904269ad',
  REACT_APP_SARDINE_ENVIRONMENT: 'sandbox',
  featuredProductsDatasourceId: '356944'
};

const environments = {
  local: {
    ...common,
    APP_SERVER: 'https://varify-us.dev-raisin.com',
    REACT_APP_OKTA_AUTH_ISSUER: 'https://deposit-solutions-us.oktapreview.com/oauth2/default',

    GATSBY_POST_LOGIN_URL: 'http://localhost:3000/en-us',
    REACT_APP_PRE_LOGIN_URL: 'http://localhost:8000/en-us',

    REACT_APP_YODLEE_FAST_LINK_URL: 'https://fl4.preprod.yodlee.com/authenticate/USDevexPreProd2-360/fastlink/?channelAppName=usdevexpreprod2',
    GATSBY_GRAPHQL_PLAYGROUND: 'https://varify-us.dev-raisin.com/ds-concierge-gql/graphql',

    REACT_APP_OKTA_CUSTOM_DOMAIN_URL: 'https://login-us.dev-raisin.com',
    PUBLIC_ASSETS_BASE_URL: 'https://assets-us.dev-raisin.com/en-us',
    LOCALE_COOKIE_DOMAIN: 'localhost',
    REACT_APP_ENABLE_TRACKING: false,
    KEYCLOAK_BASE_URL: 'https://auth-us.dev-raisin.com/auth',
    SCAS_BASE_URL: 'https://api2.dev-raisin.us/scas'
  },
  development: {
    ...common,
    APP_SERVER: 'https://varify-us.dev-raisin.com',
    REACT_APP_OKTA_AUTH_ISSUER: 'https://deposit-solutions-us.oktapreview.com/oauth2/default',

    GATSBY_POST_LOGIN_URL: 'https://app.dev-raisin.com/en-us',
    REACT_APP_PRE_LOGIN_URL: 'https://dev-raisin.com/en-us',

    REACT_APP_YODLEE_FAST_LINK_URL: 'https://fl4.preprod.yodlee.com/authenticate/USDevexPreProd2-360/fastlink/?channelAppName=usdevexpreprod2',
    GATSBY_GRAPHQL_PLAYGROUND: 'https://varify-us.dev-raisin.com/ds-concierge-gql/graphql',

    REACT_APP_OKTA_CUSTOM_DOMAIN_URL: 'https://login-us.dev-raisin.com',
    PUBLIC_ASSETS_BASE_URL: 'https://assets-us.dev-raisin.com/en-us',
    LOCALE_COOKIE_DOMAIN: 'dev-raisin.com',
    REACT_APP_ENABLE_TRACKING: true,
    KEYCLOAK_BASE_URL: 'https://auth-us.dev-raisin.com/auth',
    SCAS_BASE_URL: 'https://api2.dev-raisin.us/scas'
  },
  staging: {
    ...common,
    APP_SERVER: 'https://varify-us.staging-raisin.com',
    REACT_APP_OKTA_AUTH_ISSUER: 'https://deposit-solutions-us.oktapreview.com/oauth2/default',

    GATSBY_POST_LOGIN_URL: 'https://app.staging-raisin.com/en-us',
    REACT_APP_PRE_LOGIN_URL: 'https://staging-raisin.com/en-us',

    REACT_APP_YODLEE_FAST_LINK_URL: 'https://fl4.preprod.yodlee.com/authenticate/USDevexPreProd2-360/fastlink/?channelAppName=usdevexpreprod2', // d
    GATSBY_GRAPHQL_PLAYGROUND: 'https://varify-us.staging-raisin.com/ds-concierge-gql/graphql',

    REACT_APP_OKTA_CUSTOM_DOMAIN_URL: 'https://login-us.staging-raisin.com',
    PUBLIC_ASSETS_BASE_URL: 'https://assets-us.staging-raisin.com/en-us',

    GATSBY_GRAPHQL_SERVER_MOCKED: false,
    REACT_APP_OKTA_AUTH_AUDIENCE: 'api://default',

    LOCALE_COOKIE_DOMAIN: 'staging-raisin.com',
    REACT_APP_ENABLE_TRACKING: false,

    KEYCLOAK_BASE_URL: 'https://auth-us.staging-raisin.com/auth',
    SCAS_BASE_URL: 'https://api2.staging-raisin.us/scas'
  },
  onboarding: {
    ...common,
    APP_SERVER: 'https://varify-us.onboarding-raisin.com',
    REACT_APP_OKTA_AUTH_ISSUER: 'https://savebetter-staging.oktapreview.com/oauth2/default',

    GATSBY_POST_LOGIN_URL: 'https://app.onboarding-raisin.com/en-us',
    REACT_APP_PRE_LOGIN_URL: 'https://onboarding-raisin.com/en-us',

    REACT_APP_YODLEE_FAST_LINK_URL: 'https://fl4.preprod.yodlee.com/authenticate/USDevexPreProd2-360/fastlink/?channelAppName=usdevexpreprod2',
    GATSBY_GRAPHQL_PLAYGROUND: 'https://onboarding-varify.savebetter.com/ds-concierge-gql/graphql',

    REACT_APP_OKTA_CUSTOM_DOMAIN_URL: 'https://login-us.onboarding-raisin.com',
    PUBLIC_ASSETS_BASE_URL: 'https://assets-us.onboarding-raisin.com/en-us',

    LOCALE_COOKIE_DOMAIN: 'onboarding-raisin.com',
    REACT_APP_ENABLE_TRACKING: false,

    STORYBLOK_SPACEID: 220361,
    featuredProductsDatasourceId: '366220',

    KEYCLOAK_BASE_URL: 'https://auth-us.onboarding-raisin.com/auth',
    SCAS_BASE_URL: 'https://api2.onboarding-raisin.us/scas'
  },
  production: {
    ...common,

    APP_SERVER: 'https://varify-us.raisin.com',
    REACT_APP_OKTA_AUTH_ISSUER: 'https://savebetter.okta.com/oauth2/default',

    GATSBY_POST_LOGIN_URL: 'https://app.raisin.com/en-us',
    REACT_APP_PRE_LOGIN_URL: 'https://raisin.com/en-us',

    REACT_APP_YODLEE_FAST_LINK_URL: 'https://fl4.prod.yodlee.com/authenticate/USDevexProd2-156/fastlink/?channelAppName=usdevexprod2',
    GATSBY_GRAPHQL_PLAYGROUND: 'https://production-varify.savebetter.com/ds-concierge-gql/graphql',

    REACT_APP_OKTA_CUSTOM_DOMAIN_URL: 'https://login-us.raisin.com',
    PUBLIC_ASSETS_BASE_URL: 'https://assets-us.raisin.com/en-us',

    LOCALE_COOKIE_DOMAIN: 'raisin.com',
    REACT_APP_ENABLE_TRACKING: true,

    STORYBLOK_VERSION: 'published',
    GATSBY_GRAPHQL_SERVER_MOCKED: false,
    REACT_APP_OKTA_AUTH_AUDIENCE: 'api://default',
    REACT_APP_SARDINE_ENVIRONMENT: 'production',

    STORYBLOK_SPACEID: 220361,
    featuredProductsDatasourceId: '366220',

    KEYCLOAK_BASE_URL: 'https://auth-us.raisin.com/auth',
    SCAS_BASE_URL: 'https://api2.raisin.us/scas'
  },
};

/**
 * Configuration
 * The config object is used internally for the project.
 * The object has the  following section:
 * name: µFE name
 * port: On what port should the project run locally
 * host: URL for the application, localhost for development,
 * globals: Object used for injecting global values into the application.
 */
module.exports = {
  NODE_ENV: ENVIRONMENT,
  environmentName: ENVIRONMENT,
  accessToken: process.env.GATSBY_STORYBLOK_DELIVERY_TOKEN,
  gatsbyDatadogClientToken: process.env.GATSBY_DATADOG_CLIENT_TOKEN,
  datadogClientTokenRum: process.env.REACT_APP_DATADOG_CLIENT_TOKEN_RUM,
  datadogClientTokenLogging: process.env.REACT_APP_DATADOG_CLIENT_TOKEN_LOGGING,
  gatsbyLaunchDarklyClientSideId: process.env.GATSBY_LAUNCH_DARKLY_CLIENT_SIDE_ID,
  //TODO: delete when it's returned from server
  storyBlokOauthToken: "",
  oktaAuthClientId: process.env.REACT_APP_OKTA_AUTH_CLIENT_ID,
  launchDarklyClientSideId: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID,
  googleTagManagerId: process.env.REACT_APP_GA_UA,
  datadogCommitId: process.env.REACT_APP_COMMIT_ID,
  graphqlReactServerMocked: process.env.REACT_APP_GRAPHQL_MOCK_ENABLED,
  trustPilotApiKey: process.env.TRUSTPILOT_API_KEY,
  googleAnalyticsId: environments[ENVIRONMENT].REACT_APP_GTM_ID,
  storyBlokSpaceId: environments[ENVIRONMENT].STORYBLOK_SPACEID,
  localePrefix: environments[ENVIRONMENT].LOCALE_PREFIX,
  graphqlServer: environments[ENVIRONMENT].APP_SERVER,

  preLoginUrl: environments[ENVIRONMENT].REACT_APP_PRE_LOGIN_URL,
  postLoginUrl: environments[ENVIRONMENT].GATSBY_POST_LOGIN_URL,
  graphqlGatsbyServerMocked: environments[ENVIRONMENT].GATSBY_GRAPHQL_SERVER_MOCKED,
  publicAssetsBaseUrl: environments[ENVIRONMENT].PUBLIC_ASSETS_BASE_URL,

  version: environments[ENVIRONMENT].STORYBLOK_VERSION,
  homeSlug: environments[ENVIRONMENT].STORYBLOK_HOME_SLUG,

  yodleeFastLinkUrl: environments[ENVIRONMENT].REACT_APP_YODLEE_FAST_LINK_URL,
  yodleeFastLinkAccountId: environments[ENVIRONMENT].REACT_APP_YODLEE_FAST_LINK_ACCOUNT_ID,
  yodleeFastLinkProviderAccountId: environments[ENVIRONMENT].REACT_APP_YODLEE_FAST_LINK_PROVIDER_ACCOUNT_ID,

  oktaCustomDomainUrl: environments[ENVIRONMENT].REACT_APP_OKTA_CUSTOM_DOMAIN_URL,
  oktaAuthAudience: environments[ENVIRONMENT].REACT_APP_OKTA_AUTH_AUDIENCE || environments[ENVIRONMENT].APP_SERVER,
  oktaAuthIssuer: environments[ENVIRONMENT].REACT_APP_OKTA_AUTH_ISSUER,

  datadogApplicationId: environments[ENVIRONMENT].REACT_APP_DATADOG_APPLICATION_ID,
  bundleReport: environments[ENVIRONMENT].BUNDLE_REPORT || false,

  localeCookieDomain: environments[ENVIRONMENT].LOCALE_COOKIE_DOMAIN,
  enableTracking: environments[ENVIRONMENT].REACT_APP_ENABLE_TRACKING,
  sardineClientId: process.env.REACT_APP_SARDINE_CLIENT_ID,
  sardineEnvironment: environments[ENVIRONMENT].REACT_APP_SARDINE_ENVIRONMENT,
  featuredProductsDatasourceId: environments[ENVIRONMENT].featuredProductsDatasourceId,

  keycloakBaseUrl: environments[ENVIRONMENT].KEYCLOAK_BASE_URL,
  scasBaseUrl: environments[ENVIRONMENT].SCAS_BASE_URL
};

